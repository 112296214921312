<template>
<!--   <div style="overflow-x: scroll;">
    <div style="width: 120vw;"> -->
      <v-data-table
        v-model="selected"
        :show-select="totaleEnti > 0"
        item-key="ente"
        :headers="headers"
        :items="enti"
        :options.sync="options"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 15, 50, 100, 500, 10000]
        }"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        show-expand      
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Enti per Asseverazione 1.4.1</v-toolbar-title>
            <v-spacer></v-spacer>
            <span class="mr-10">Totale enti: {{ numeroEnti }}</span>
            <span class="mr-10">Multifornitore: {{ numeroMultifornitore }}</span>
            <span class="mr-10">In asseverazione: {{ numeroInAsseverazione }}</span>
            <span>Asseverabili: {{ numeroAsseverabili }}</span>
            
          </v-toolbar>
        <filtri @ricerca="onRicerca"/>
        </template>
        <template v-slot:[`item.area`]="{ item }">
          <span :title="`${item.regione} - ${item.provincia}`">{{ item.area }} ({{ item.provincia }})</span>
        </template>
<!--         <template v-slot:[`item.ente`]="{ item }">
          <a :href="item.linkCandidatura" target="_blank">{{ item.ente.substring(10) }}</a>
        </template>   -->    
        <template v-slot:[`item.ente`]="{ item }">
          <div>{{ item.ente.replace('Comune di ', '').replace('Comune ', '').replace('COMUNE DI ', '') }}</div>
          <span class="mr-5 text-caption"><a :href="item.linkCandidatura" target="_blank" title="Apre la candidatura su Salesforce">Candidatura</a></span>
          <span class="text-caption"><a :href="item.urlSito" target="_blank" title="Apre il sito web del comune">Sito web</a></span>
        </template>          
<!--         <template v-slot:[`item.urlSito`]="{ item }">
          <a :href="item.urlSito" target="_blank">{{ item.urlSito }}</a>
        </template> -->
        <template v-slot:[`item.statoCrawler`]="{ item }">
          <a :href="item.reportCrawler">{{ item.statoCrawler }}</a>
        </template>
        <template v-slot:[`item.inAsseverazione`]="{ item }">
          <v-simple-checkbox v-model="item.inAsseverazione" disabled />
        </template>
        <template v-slot:[`item.statoProgetto`]="{ item }">
          <div>{{  item.statoProgetto }}</div>                                               
        </template>
        <template v-slot:[`item.asseveratoPositivo`]="{ item }">               
          <v-simple-checkbox v-model="item.asseveratoPositivo" disabled />
        </template>
        <template v-slot:[`item.asseverabile`]="{ item }">
          <v-simple-checkbox v-model="item.asseverabile" disabled />
        </template>        
        <template v-slot:[`item.dataUltimoEsitoCrawler`]="{ item }">
          {{ item.dataUltimoEsitoCrawler | date }}
        </template>
        <template v-slot:[`item.elencoFornitori`]="{ item }">
          <span v-html="item.elencoFornitori"></span>
        </template>
        <template v-slot:[`item.ggAsseverazione`]="{ item }">
          <span :title="`In asseverazione da: ${formatDate(item.dataIngressoAsseverazione)}`">{{ item.ggAsseverazione }}</span>
        </template>                
        <template v-slot:no-data>
          <v-btn color="primary" @click="getDataFromApi">Ricarica</v-btn>
        </template>
        <template v-slot:[`header.inAsseverazione`]><span title="Ente in asseverazione">In Ass.</span></template>
        <template v-slot:[`header.statoProgetto`]><span title="Stato progetto">Stato</span></template>
        <template v-slot:[`header.asseveratoPositivo`]><span title="Ente asseverato positivo">Ass.Po</span></template>
        <template v-slot:[`header.asseverabile`]><span title="Ente asseverabile">Assev.</span></template>
        <template v-slot:[`header.statoCrawler`]><span title="Stato crawler o numero errori (+link crawler)">Crawler</span></template>
        <template v-slot:[`header.ggAsseverazione`]><span title="Numero di giorni in asseverazione">#Giorni</span></template>
        <template v-slot:[`header.numeroServizi`]><span title="Numero di servizi candidati">#Servizi</span></template>
        <template v-slot:[`header.numeroValidati`]><span title="Numero di servizi già validati">#Validati</span></template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="entiSelected">
            <v-tabs dense v-model="tab[item.ente]" @change="onChangeTabs(item, $event)">
              <v-tab class="entiSelected">Servizi</v-tab>
              <v-tab>Esiti<v-btn icon v-if="item.loadingDetail" :loading="true"><v-icon>mdi-refresh</v-icon></v-btn></v-tab>
              <v-tab>Report crawler<v-btn icon v-if="item.loadingDetail" :loading="true"><v-icon>mdi-refresh</v-icon></v-btn></v-tab>
              <!-- v-if="Object.values(item.esiti).join('').trim().length" -->
            </v-tabs>
            <v-tabs-items v-model="tab[item.ente]">
              <v-tab-item>
                <dettaglio-servizi :servizi="item.servizi"/>
              </v-tab-item>
              <v-tab-item >
                <esiti :esiti="item.esiti" :crawler="item.crawler" :loading="item.loadingDetail" @refreshRiepilogo="refreshRiepilogo(item)"/>
              </v-tab-item>
              <v-tab-item>
                <report-crawler :report="item.htmlCrawler"/>
              </v-tab-item>
            </v-tabs-items>
          </td>
        </template>
        <template v-slot:[`footer.prepend`]>
          <v-btn class = "ml-5" small color="info" @click.stop="onSelezionaTutti" title="Gestisce la selezione di tutti gli enti caricati(anche pagine successive)">{{ selezionaTutti ? 'Annulla selezione' : 'Seleziona tutti' }}</v-btn>
          <v-btn :disabled="selected.length === 0" class = "ml-5" small color="success" @click.stop="onEsportaExcel" title="Esporta su Excel gli enti selezionati">Esporta su Excel</v-btn>
        </template>
      </v-data-table>
<!--     </div>
  </div> -->
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import excelService from '@/services/excelService'
  import Filtri from './filtri'
  import selfapi from '@/services/selfapi.js'
  import DettaglioServizi from './elementi/servizi'
  import Esiti from './elementi/esiti'
  import ReportCrawler from './elementi/reportCrawler'

  export default {
    components: {
      Filtri,
      DettaglioServizi,
      Esiti,
      ReportCrawler
    },
    data () {
      return {
        totaleEnti: 0,
        enti: [],
        selected: [],
        loading: true,
        options: {},   
        headers: [
          { text: 'Area', value: 'area', align: 'start'},
          { text: 'Ente', value: 'ente'},
          { text: 'Asseveratore', value: 'asseveratore'},
          { text: 'In ass.', value: 'inAsseverazione'},
          { text: 'Stato', value: 'statoProgetto'},
          { text: 'AssPo', value: 'asseveratoPositivo'},          
          { text: 'Assev.', value: 'asseverabile'},
          { text: 'Crawler', value: 'statoCrawler'},
          { text: 'Versione', value: 'versioneCrawler'},
          { text: 'Data Crawler', value: 'dataUltimoEsitoCrawler'},
          { text: '#Giorni', value: 'ggAsseverazione', align: 'center'},
          { text: '#Servizi', value: 'numeroServizi', align: 'center'},
          { text: '#Validati', value: 'numeroValidati', align: 'center'},
          { text: 'Fornitori', value: 'elencoFornitori', align: 'start'},
          { text: '', value: 'data-table-expand'}
        ],
        expanded: [],
        singleExpand: false,
        dialogErrore: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: null,
        defaultItem: null,
        filtri: null,
        tab: {},
        selezionaTutti: false,
        numeroEnti: '',
        numeroInAsseverazione: '',
        numeroAsseverabili: '',
        numeroMultifornitore: ''
      }
    },
    watch: {
      options: {
        async handler () {
          // await this.getDataFromApi()
        },
        deep: true
      },
      async refreshList(value) {
        if (value) {
          await this.getDataFromApi()
        }
      }
    },
    async mounted () {
      // await this.getDataFromApi()
    },
    methods: {
      fornitori(item) {
        const elenco = _.uniq(item.servizi.map(x => x.fornitore))
        return elenco.join('<br/>')
      },
      formatDate(date) {
        return moment(date).format('DD/MM/YYYY')
      },
      async onChangeTabs(item, event) {
         if (!item.htmlCrawler) {
          await this.refreshRiepilogo(item)
        }
      },
      async refreshRiepilogo(item) {
        item.loadingDetail = true
        const riepilogo = await selfapi.getInfoReportCrawler(item.ente, true)
        item.htmlCrawler = riepilogo.contenuto
        delete riepilogo.contenuto
        item.crawler = riepilogo
        item.loadingDetail = false
      },
      onSelezionaTutti() {
        if (!this.selezionaTutti) {
          this.selezionaTutti = true
          this.selected = [...this.enti]
        } else {
          this.selezionaTutti = false
          this.annullaSelezione()
        }
      },
      annullaSelezione() {
        while(this.selected.length > 0) { this.selected.pop() }
      },
      async onEsportaExcel() {
        let daEsportare = []
        for (let e of this.selected) {
          const enteBase = {
            area: e.area,
            regione: e.regione,
            provincia: e.provincia,
            statoProgetto: e.statoProgetto,
            asseveratoPositivo: e.asseveratoPositivo ? 'SI' : 'NO',            
            ente: e.ente,
            asseveratore: e.asseveratore,
            inAsseverazione: e.inAsseverazione ? 'SI' : 'NO',
            asseverabile: e.asseverabile ? 'SI': 'NO',
            esitoCrawler: e.statoCrawler || '',
            versioneCrawler: e.versioneCrawler || '',
            dataCrawler: moment(e.dataUltimoEsitoCrawler).format('L'),
            giorniAsseverazione: e.ggAsseverazione,
            numeroServizi: e.numeroServizi,
            numeroValidati: e.numeroValidati,
            numeroFornitori: e.numeroFornitori,
            urlSito: e.urlSito,  
            // servizi: e.servizi.map(x => `${x.servizio} (${x.validato ? 'OK: ' : ''}${x.fornitore})`).join(', '),
            fornitori: _.uniq(e.servizi.map(x => `${x.fornitore}`)).join(', ')
          }
          // daEsportare.push(ente)
          // Separiamo i servizi "Sito Internet" dagli altri
          let serviziOrdinati = [...e.servizi];
          
          // Filtriamo tutti i servizi che si chiamano "Sito Internet"
          const sitoInternetServizi = serviziOrdinati.filter(servizio => servizio.servizio.toLowerCase() === 'sito internet');

          // Se esistono servizi "Sito Internet", concatenare i fornitori
          if (sitoInternetServizi.length > 0) {
            const fornitoriConcatenati = sitoInternetServizi.map(s => s.fornitore).join(', ');
            
            // Inseriamo "Sito Internet" come primo servizio con fornitori concatenati
            enteBase[`servizio1`] = 'Sito Internet';
            enteBase[`fornitore1`] = fornitoriConcatenati;
            
            // Rimuoviamo "Sito Internet" dai servizi ordinati
            serviziOrdinati = serviziOrdinati.filter(servizio => servizio.servizio.toLowerCase() !== 'sito internet');
          }

          // Aggiungi gli altri servizi nelle colonne successive
          serviziOrdinati.forEach((servizio, index) => {
            enteBase[`servizio${index + 2}`] = servizio.servizio; // Colonna per il servizio (index + 2 perché il primo è occupato da "Sito Internet")
            enteBase[`fornitore${index + 2}`] = servizio.fornitore; // Colonna per il fornitore
          });

          daEsportare.push(enteBase); // Aggiungi il nuovo oggetto alla lista da esportare          
        }

        await excelService.creaExcelFromArray(daEsportare, 'EntiAsseverazione141.xlsx')
        // this.annullaSelezione()
      },
      async onRicerca(filtri) {
        this.filtri = filtri
        await this.getDataFromApi()
      },
      async getDataFromApi () {
        try {
          this.loading = true
          const result = await selfapi.entiAsseverabili(this.filtri.asseverazione, this.filtri.asseverabili)
          this.numeroEnti = result.numeroEnti
          this.numeroInAsseverazione = result.inAsseverazione
          this.numeroMultifornitore = result.multifornitore
          this.numeroAsseverabili = result.asseverabili
          let entiRaw = result.enti
          if (this.filtri.ente) {
            const ft = this.filtri.ente.toLowerCase()
            entiRaw = entiRaw.filter(x => x.ente.toLowerCase().includes(ft))
          }
          if (this.filtri.area && this.filtri.area.length > 0) {
            const ft = this.filtri.area
            entiRaw = entiRaw.filter(x => ft.includes(x.area))
          }
          if (this.filtri.asseveratore) {
            const ft = this.filtri.asseveratore.toLowerCase()
            entiRaw = entiRaw.filter(x => x.asseveratore.toLowerCase().includes(ft))
          }
          if(this.filtri.statoProgetto) {
            const ft = this.filtri.statoProgetto.toLowerCase()
            entiRaw = entiRaw.filter(x => x.statoProgetto.toLowerCase().includes(ft))
          }
          if(this.filtri.asseveratoPositivo) {
            entiRaw = entiRaw.filter(x => x.asseveratoPositivo === true)
          }          
          if (this.filtri.fornitore) {
            const ft = this.filtri.fornitore.toLowerCase()
            entiRaw = entiRaw.filter(x => x.servizi.some(x => x.fornitore.toLowerCase().includes(ft)))
          }
          if (this.filtri.servizio) {
            const ft = this.filtri.servizio.toLowerCase()
            entiRaw = entiRaw.filter(x => x.servizi.some(x => x.servizio.toLowerCase().includes(ft)))
          }
          if (this.filtri.singoloFornitore) {
            entiRaw = entiRaw.filter(x => x.numeroFornitori === 1)
          }
          if (this.filtri.multiFornitore) {
            entiRaw = entiRaw.filter(x => x.numeroFornitori > 1)
          }          
          if (this.filtri.soloInformato) {
            const ft = 'Sito Internet'.toLowerCase()
            entiRaw = entiRaw.filter(x => x.servizi.every(x => x.servizio.toLowerCase() === ft))
          }
          if (this.filtri.koCrawler) {
            entiRaw = entiRaw.filter(x => x.statoCrawler !== 'OK' && x.dataUltimoEsitoCrawler)
          }
          if (this.filtri.validati) {
            entiRaw = entiRaw.filter(x => x.servizi.every(x => x.validato))
          }          
          
          if (this.filtri.start) {
            entiRaw = entiRaw.filter(x => moment(x.dataUltimoEsitoCrawler).diff(moment(this.filtri.start, 'YYYY-MM-DD'), 'minutes') >= 0)
          }
          if (this.filtri.end) {
            entiRaw = entiRaw.filter(x => moment(x.dataUltimoEsitoCrawler).diff(moment(this.filtri.end, 'YYYY-MM-DD').endOf('day'), 'minutes') <= 0)
          }
          
          if (this.filtri.startAss) {
            entiRaw = entiRaw.filter(x => moment(x.dataIngressoAsseverazione).diff(moment(this.filtri.startAss, 'YYYY-MM-DD'), 'minutes') >= 0)
          }
          if (this.filtri.endAss) {
            entiRaw = entiRaw.filter(x => moment(x.dataIngressoAsseverazione).diff(moment(this.filtri.endAss, 'YYYY-MM-DD').endOf('day'), 'minutes') <= 0)
          }
          this.enti = entiRaw.map(x => { return {...x, elencoFornitori: this.fornitori(x) }})
          this.totaleEnti = entiRaw.length

        } catch(err) {
          this.enti = []
          this.totaleEnti = 0
        } finally {
          this.loading = false
        }
      },
      close () {
      }
    }
  }
</script>

<style lang="scss">
  tr.v-data-table__expanded__content  { // TODO: Solo su questa pagina !
    box-shadow: none !important;
  }
  
  td.entiSelected {
    border-style: solid!important;
    border-width: 2px!important;
    border-color: #1976d2 !important;
  }

</style>
